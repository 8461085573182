import {
  DANNO_URL,
  approveCancellation,
  approveRefund,
  findCancellations,
  findRefunds,
} from "../api";
import {
  AdminFindOtherRefundsRes,
  CENTRAL_TZ,
  CancellationDO,
  MMM_d_yyyy,
  RefundDO,
  arrOfSize,
  daysUntilDepartureEOD,
  feeLabel,
  feePaymentEx,
  formatCents,
  groupGuestsByRoom,
  h_mm_a,
  isLastItem,
  lowerCaseMeridiem,
  refundTotalPerGuest,
  roomGuestsFrom,
  safeId,
  validateBookingNumber,
} from "data-model";
import {
  AdjustmentItem,
  CancelFeeItem,
  CancelSelector,
  DaysPriorToTour,
  DottedHeading,
  ErrorMessage,
  EstimatedRefund,
  ExternalLink,
  FeeItem,
  GuestBox,
  Input,
  RoomBox,
  SVG,
  Select,
  Textarea,
  useErrors,
  useModal,
} from "react-components";
import clsx from "clsx";
import {
  CSSProperties,
  FC,
  Fragment,
  PropsWithChildren,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState,
} from "react";
import { DateTime } from "luxon";

const dateFormat = `${MMM_d_yyyy} 'at' ${h_mm_a}`;

const Refunds = () => (
  <main className="padding-top-2 padding-x-4 is-flex">
    <div>
      <Cancellations />
    </div>
    <div className="margin-left-10">
      <OtherRefunds />
    </div>
  </main>
);

export { Refunds };

const Cancellations = () => {
  const setModal = useModal();

  const [pending, setPending] = useState<CancellationDO[]>([]);
  const [pendingLimit, setPendingLimit] = useState(10);
  const [pendingOffset, setPendingOffset] = useState(0);

  const [processed, setProcessed] = useState<CancellationDO[]>([]);
  const [processedLimit, setProcessedLimit] = useState(5);
  const [processedOffset, setProcessedOffset] = useState(0);
  const [bookingNumber, setBookingNumber] = useState("");

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const syncPending = async () => {
    const pending = await findCancellations({
      limit: pendingLimit,
      offset: pendingOffset,
    });
    setPending(pending);
  };

  const syncProcessed = async (signal?: AbortSignal) => {
    const processed = await findCancellations(
      {
        limit: processedLimit,
        offset: processedOffset,
        approved: true,
        ...(bookingNumber && {
          bookingNumber,
        }),
      },
      { signal }
    );
    setProcessed(processed);
  };

  const run = (fn: () => Promise<unknown>) => {
    setIsLoading(true);
    catchErrors(fn, () => setIsLoading(false));
  };

  // These two effects run on mount, as intended.

  useEffect(() => {
    run(syncPending);
  }, [pendingLimit, pendingOffset]);

  useEffect(() => {
    const controller = new AbortController();

    setIsLoading(true);
    catchErrors(
      async () => {
        if (bookingNumber) {
          if (validateBookingNumber(bookingNumber)) {
            await syncProcessed(controller.signal);
          } else {
            setProcessed([]);
          }
        } else {
          await syncProcessed(controller.signal);
        }
      },
      () => setIsLoading(false)
    );

    return () => controller.abort();
  }, [processedLimit, processedOffset, bookingNumber]);

  const handleView = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { id, approvedAt } = e.currentTarget.dataset;
    if (!id) throw new Error("unreachable");
    const cancellation = (approvedAt ? processed : pending).find(
      (c) => c.id === +id
    )!;

    setModal({
      isOpen: true,
      body: (
        <CancelDetails
          cancellation={cancellation}
          onApprove={() => {
            run(() => Promise.all([syncPending(), syncProcessed()]));
          }}
          onClose={handleModalClose}
        />
      ),
    });
  };

  const handleModalClose = () =>
    setModal({ isOpen: false, body: null, className: "" });

  return (
    <>
      <h2 className="is-flex margin-top-0 is-size-1">
        Cancellations
        {isLoading && (
          <SVG
            path="site/icon/spinner"
            alt="Spinner"
            className="margin-left-1"
            height={20}
          />
        )}
      </h2>

      <ErrorMessage errors={errors} />

      <h3 className="is-size-2">Pending</h3>
      <CancelTable
        items={pending}
        limit={pendingLimit}
        offset={pendingOffset}
        onLimitChange={(limit) => {
          setPendingLimit(limit);
          setPendingOffset(0);
        }}
        onOffsetChange={setPendingOffset}
        onView={handleView}
        pending
      />

      <div className="is-flex is-justify-content-space-between is-align-items-center margin-y-2">
        <h3 className="is-size-2 is-marginless">Processed</h3>
        <Input
          className="is-width-auto"
          placeholder="Booking #"
          style={{ width: 150 }}
          value={bookingNumber}
          onChange={(e) => {
            setBookingNumber(e.currentTarget.value.trim());
            setProcessedOffset(0);
          }}
        />
      </div>

      <CancelTable
        items={processed}
        limit={processedLimit}
        offset={processedOffset}
        onLimitChange={(limit) => {
          setProcessedLimit(limit);
          setProcessedOffset(0);
        }}
        onOffsetChange={setProcessedOffset}
        onView={handleView}
      />
    </>
  );
};

interface CancelTable
  extends Omit<TableProps<CancellationDO>, "columns" | "idPrefix"> {
  onView: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CancelTable: FC<CancelTable> = ({ onView, ...rest }) => (
  <Table {...rest} idPrefix="cancellations" columns={6}>
    <strong className="has-background-white padding-2">Booking #</strong>
    <strong className="has-background-white padding-2">Type</strong>
    <strong className="has-background-white padding-2">Requested By</strong>
    <strong className="has-background-white padding-2">Submitted Via</strong>
    <strong className="has-background-white padding-2">
      {rest.pending ? "Created" : "Processed"} On
    </strong>
    <span
      className="has-background-white is-fullheight"
      style={{ minWidth: 65 }}
    />
    {rest.items.map((cancellation) => {
      return (
        <Fragment key={cancellation.id}>
          <ExternalLink
            className="has-background-white padding-2"
            href={`${DANNO_URL}/manage-booking?booking-id=${cancellation.booking.id}&account-id=${cancellation.booking.owner.id}`}
            target="_blank"
          >
            {cancellation.booking.number}
          </ExternalLink>
          <span className="has-background-white padding-2">
            {cancellation.spots.every((s) => s.cancelled) ? "Full" : "Partial"}
          </span>
          <span className="has-background-white padding-2">
            {cancellation.requestedBy}
          </span>
          <span className="has-background-white padding-2">
            {cancellation.loginId || "Online"}
          </span>
          <span className="has-background-white padding-2">
            {lowerCaseMeridiem(
              DateTime.fromISO(
                rest.pending
                  ? cancellation.createdAt
                  : cancellation.approvedAt!,
                { zone: CENTRAL_TZ }
              ).toFormat(dateFormat)
            )}
          </span>
          <span className="has-background-white padding-x-2 is-fullheight is-flex is-align-items-center">
            <button
              className="button is-medium"
              onClick={onView}
              data-id={cancellation.id}
              data-approved-at={cancellation.approvedAt}
            >
              View
            </button>
          </span>
        </Fragment>
      );
    })}
  </Table>
);

interface TableProps<T> extends PropsWithChildren {
  columns: number;
  idPrefix: string;
  items: T[];
  limit: number;
  offset: number;
  onLimitChange: (limit: number) => void;
  onOffsetChange: (offset: number) => void;
  pending?: boolean;
}

const Table: FC<TableProps<unknown>> = ({
  columns,
  idPrefix,
  children,
  items,
  limit,
  offset,
  onLimitChange,
  onOffsetChange,
  pending = false,
}) => {
  const limitId = `${pending ? "pending" : "processed"}-${idPrefix}-limit`;

  return (
    <>
      <div
        className="is-grid is-gap-1px has-background-gray has-border-gray"
        style={{ gridTemplateColumns: `repeat(${columns}, auto)` }}
      >
        {children}
        {items.length < limit &&
          arrOfSize(columns * (limit - items.length)).map((_, idx) => (
            <span
              key={idx}
              className="has-background-white"
              style={{ height: 36 }}
            />
          ))}
      </div>
      <div className="is-flex is-align-items-center margin-top-2">
        <div className="is-flex is-align-items-center is-flex-1">
          <label htmlFor={limitId} className="margin-right-1">
            Display:
          </label>
          <Select
            id={limitId}
            value={limit}
            onChange={(e) => onLimitChange(+e.currentTarget.value)}
          >
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>30</option>
            <option>50</option>
          </Select>
        </div>
        <span className="is-flex-1">
          Page: {!!items.length && (offset + limit) / limit}
        </span>
        <div className="is-flex">
          <button
            className="button is-ghost is-link is-paddingless margin-right-3"
            disabled={offset === 0}
            onClick={() => onOffsetChange(offset - limit)}
          >
            Previous
          </button>
          <button
            className="button is-ghost is-link is-paddingless"
            disabled={!items.length || items.length < limit}
            onClick={() => onOffsetChange(offset + limit)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

interface CancelDetailsProps {
  cancellation: CancellationDO;
  onApprove: () => void;
  onClose: () => void;
}

const CancelDetails: FC<CancelDetailsProps> = ({
  cancellation,
  onApprove,
  onClose,
}) => {
  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const createdAt = DateTime.fromISO(cancellation.createdAt, {
    zone: CENTRAL_TZ,
  });

  const owner = cancellation.booking.owner;
  const guests = cancellation.spots.map((s) => s.guest);
  const guestsToCancel = cancellation.spots.map((s) => s.cancelled);
  const roomGuests = roomGuestsFrom(cancellation.spots);
  const roomsToCancel = groupGuestsByRoom(guestsToCancel, roomGuests);
  let posIdx = -1;

  const totalRefund = cancellation.refunds.reduce((acc, r) => acc + r.total, 0);
  const unsettledPayments = cancellation.refunds
    .map((r) => r.payment)
    .filter((p) => p.transactionStatus !== "settledSuccessfully");

  const handleApprove = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        await approveCancellation(cancellation.id);
        onApprove();
        onClose();
      },
      () => setIsLoading(false)
    );
  };

  const combinedErrors = errors.slice();
  if (totalRefund > 0 && unsettledPayments.length) {
    combinedErrors.push({
      message: `One or more payments have not settled yet: ${unsettledPayments
        .map((p) => `${p.cardBrand} (...${p.cardLastFour})`)
        .join(", ")}`,
    });
  }

  return (
    <div id="modal-description" className="is-flex">
      <aside className="margin-left-2 margin-right-4">
        <CancelSelector
          guests={guests}
          guestsToCancel={guestsToCancel}
          onChange={() => {}}
          owner={owner}
          roomGuests={roomGuests}
        >
          <p className="margin-top-0 margin-bottom-3">
            <strong>{cancellation.booking.number}</strong>
          </p>
        </CancelSelector>
      </aside>
      <div>
        <p className="margin-top-0">
          Created: {lowerCaseMeridiem(createdAt.toFormat(dateFormat))}
          <DaysPriorToTour
            daysLeft={daysUntilDepartureEOD(
              cancellation.booking.departedAt,
              createdAt
            )}
          />
        </p>
        <div className="margin-bottom-4">
          <div>
            <div
              style={{ minHeight: 348 }}
              className="is-grid is-grid-template-columns-repeat-3-1fr is-column-gap-4 is-row-gap-4 is-align-items-flex-start"
            >
              {roomsToCancel.map((room, roomIdx) => {
                const oldGuestsInRoom = room.length; // pre-cancellation
                const newGuestsInRoom = room.filter((c) => !c).length; // that are staying post-cancellation

                if (oldGuestsInRoom === newGuestsInRoom) {
                  // Everyone in the room stays
                  posIdx += room.length;
                  return null;
                }

                return (
                  <RoomBox
                    key={roomIdx}
                    oldGuestsInRoom={oldGuestsInRoom}
                    newGuestsInRoom={newGuestsInRoom}
                  >
                    {room.map((isCancelled, guestIdx) => {
                      ++posIdx;

                      const guest = guests[posIdx];
                      const adjustment = guest.fees.find(
                        (f) => Number(f.cancellation) === cancellation.id // fee.cancellation is not populated
                      );

                      const children = adjustment ? (
                        <AdjustmentItem
                          newGuestsInRoom={newGuestsInRoom}
                          total={adjustment.amount + adjustment.tax}
                        />
                      ) : isCancelled ? (
                        <>
                          <p
                            className="margin-y-2 is-grid is-column-gap-2"
                            style={{ gridTemplateColumns: "2fr 4fr 1fr" }}
                          >
                            {guest.fees.map((fee) => (
                              <FeeItem
                                key={fee.id}
                                fee={fee}
                                payment={feePaymentEx(fee, cancellation)}
                              />
                            ))}
                          </p>
                          {guest.cancellationFees.map((fee, feeIdx) => (
                            <CancelFeeItem
                              key={feeIdx}
                              amount={fee.amount}
                              // onChange={(value) =>
                              //   onCancelFeeChange?.(
                              //     roomIdx,
                              //     guestIdx,
                              //     feeIdx,
                              //     value
                              //   )
                              // }
                              type={fee.type}
                            />
                          ))}
                          <div className="margin-top-2">
                            {cancellation.refunds.map((refund) => {
                              const totals = refundTotalPerGuest(refund);

                              if (!totals[guest.id]) return null;

                              return (
                                <EstimatedRefund
                                  key={refund.id}
                                  total={totals[guest.id]}
                                >
                                  Refund {refund.payment.cardBrand} (...
                                  {refund.payment.cardLastFour})
                                </EstimatedRefund>
                              );
                            })}
                          </div>
                        </>
                      ) : null;

                      return (
                        <GuestBox
                          key={posIdx}
                          className={clsx(
                            !isLastItem(guestIdx, room.length) &&
                              "has-border-bottom-gray"
                          )}
                          guests={guests}
                          isCancelling={isCancelled}
                          owner={owner}
                          posIdx={posIdx}
                        >
                          {children}
                        </GuestBox>
                      );
                    })}
                  </RoomBox>
                );
              })}
            </div>
            <hr className="margin-top-4 margin-bottom-3" />
            <div className="is-flex is-justify-content-space-between">
              <div>
                <label htmlFor="cancellation-reason">
                  Reason for cancellation
                </label>
                <Textarea
                  id="cancellation-reason"
                  value={cancellation.reason}
                  readOnly
                />
              </div>
              <div className="margin-x-4 is-flex is-flex-direction-column">
                <label htmlFor="cancellation-requested-by">
                  Requested By (shows on PDF)
                </label>
                <Input
                  id="cancellation-requested-by"
                  value={cancellation.requestedBy}
                  readOnly
                />
              </div>
              <div style={{ minWidth: 300 }} className="margin-right-4">
                {cancellation.refunds.map((refund) => (
                  <p key={refund.id} className="margin-y-0 is-flex is-size-2">
                    <strong className="is-flex-1">
                      Total Refund {refund.payment.cardBrand} (...
                      {refund.payment.cardLastFour})
                    </strong>
                    <strong>{formatCents(refund.total, false)}</strong>
                  </p>
                ))}
                <DottedHeading
                  className={clsx(
                    "margin-y-0",
                    totalRefund > 0 && "has-text-green"
                  )}
                  heading="h2"
                  left="Grand Total Refund"
                  right={formatCents(totalRefund, false)}
                />
              </div>
              {!cancellation.approvedAt && (
                <div className="is-flex is-flex-direction-column">
                  <button
                    className="button padding-y-1 padding-x-2 is-light-blue is-outlined is-size-7 padding-x-4 margin-bottom-1"
                    onClick={onClose}
                    disabled={isLoading}
                  >
                    Close – Do Not Approve
                  </button>
                  <button
                    className="button padding-y-1 padding-x-2 is-light-blue is-size-7"
                    onClick={handleApprove}
                    disabled={
                      isLoading ||
                      (totalRefund > 0 && !!unsettledPayments.length)
                    }
                  >
                    Approve
                  </button>
                </div>
              )}
            </div>
            <ErrorMessage
              errors={combinedErrors}
              className="margin-top-2 margin-bottom-0 is-justify-content-flex-end is-grid-column-1-neg-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const OtherRefunds = () => {
  const setModal = useModal();

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const [pending, setPending] = useState<AdminFindOtherRefundsRes>([]);
  const [pendingLimit, setPendingLimit] = useState(10);
  const [pendingOffset, setPendingOffset] = useState(0);

  const [processed, setProcessed] = useState<AdminFindOtherRefundsRes>([]);
  const [processedLimit, setProcessedLimit] = useState(5);
  const [processedOffset, setProcessedOffset] = useState(0);

  const syncPending = async () => {
    const pending = await findRefunds({
      limit: pendingLimit,
      offset: pendingOffset,
    });
    setPending(pending);
  };

  const syncProcessed = async () => {
    const processed = await findRefunds({
      limit: processedLimit,
      offset: processedOffset,
      approved: true,
    });
    setProcessed(processed);
  };

  const run = (fn: () => Promise<unknown>) => {
    setIsLoading(true);
    catchErrors(fn, () => setIsLoading(false));
  };

  // These two effects run on mount, as intended.

  useEffect(() => {
    run(syncPending);
  }, [pendingLimit, pendingOffset]);

  useEffect(() => {
    run(syncProcessed);
  }, [processedLimit, processedOffset]);

  const handleView = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { id, approvedAt } = e.currentTarget.dataset;
    if (!id) throw new Error("unreachable");
    const [refund] = (approvedAt ? processed : pending).find(
      (pair) => pair[0].id === +id
    )!;

    setModal({
      isOpen: true,
      body: (
        <RefundDetails
          refund={refund}
          onApprove={() => {
            run(() => Promise.all([syncPending(), syncProcessed()]));
          }}
          onClose={handleModalClose}
        />
      ),
    });
  };

  const handleModalClose = () =>
    setModal({ isOpen: false, body: null, className: "" });

  return (
    <>
      <h2 className="is-flex margin-top-0 is-size-1">
        Other Refunds
        {isLoading && (
          <SVG
            path="site/icon/spinner"
            alt="Spinner"
            className="margin-left-1"
            height={20}
          />
        )}
      </h2>

      <ErrorMessage errors={errors} />

      <h3 className="is-size-2">Pending</h3>

      <RefundTable
        items={pending}
        limit={pendingLimit}
        offset={pendingOffset}
        onLimitChange={(limit) => {
          setPendingLimit(limit);
          setPendingOffset(0);
        }}
        onOffsetChange={setPendingOffset}
        onView={handleView}
        pending
      />

      <h3 className="is-size-2">Processed</h3>

      <RefundTable
        items={processed}
        limit={processedLimit}
        offset={processedOffset}
        onLimitChange={(limit) => {
          setProcessedLimit(limit);
          setProcessedOffset(0);
        }}
        onOffsetChange={setProcessedOffset}
        onView={handleView}
      />
    </>
  );
};

interface RefundTable
  extends Omit<
    TableProps<AdminFindOtherRefundsRes[0]>,
    "columns" | "idPrefix"
  > {
  onView: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const RefundTable: FC<RefundTable> = ({ onView, ...rest }) => (
  <Table {...rest} idPrefix="refunds" columns={6}>
    <strong className="has-background-white padding-2">Booking #</strong>
    <strong className="has-background-white padding-2">Type</strong>
    <strong className="has-background-white padding-2">Requested By</strong>
    <strong className="has-background-white padding-2">Submitted Via</strong>
    <strong className="has-background-white padding-2">
      {rest.pending ? "Created" : "Processed"} On
    </strong>
    <span
      className="has-background-white is-fullheight"
      style={{ minWidth: 65 }}
    />
    {rest.items.map(([refund, booking]) => (
      <Fragment key={refund.id}>
        <ExternalLink
          className="has-background-white padding-2"
          href={`${DANNO_URL}/manage-booking?booking-id=${booking.id}`}
          target="_blank"
        >
          {booking.number}
        </ExternalLink>
        <span className="has-background-white padding-2">{refund.type}</span>
        <span className="has-background-white padding-2">
          {refund.requestedBy}
        </span>
        <span className="has-background-white padding-2">{refund.loginId}</span>
        <span className="has-background-white padding-2">
          {lowerCaseMeridiem(
            DateTime.fromISO(
              rest.pending ? refund.createdAt : refund.submittedAt!,
              { zone: CENTRAL_TZ }
            ).toFormat(dateFormat)
          )}
        </span>
        <span className="has-background-white padding-x-2 is-fullheight is-flex is-align-items-center">
          <button
            className="button is-medium"
            onClick={onView}
            data-id={refund.id}
            data-approved-at={refund.submittedAt}
          >
            View
          </button>
        </span>
      </Fragment>
    ))}
  </Table>
);

const maxWidth: CSSProperties = { maxWidth: 500 };

interface RefundDetailsProps {
  onApprove: () => void;
  onClose: () => void;
  refund: RefundDO;
}

const RefundDetails: FC<RefundDetailsProps> = ({
  onApprove,
  onClose,
  refund,
}) => {
  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const isUnsettled =
    refund.payment.transactionStatus !== "settledSuccessfully";

  const combinedErrors = errors.slice();
  if (isUnsettled) {
    combinedErrors.push({
      message: `Payment has not settled yet: ${refund.payment.cardBrand} (...${refund.payment.cardLastFour})`,
    });
  }

  const handleApprove = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        await approveRefund(refund.id);
        onApprove();
        onClose();
      },
      () => setIsLoading(false)
    );
  };

  return (
    <div id="modal-description">
      <div
        className="is-grid is-row-gap-1"
        style={{ gridTemplateColumns: "repeat(3, auto)" }}
      >
        {Object.entries(refundTotalPerGuest(refund)).map(
          ([guestId, guestTotal]) => {
            const fees = refund.lineItems
              .map((item) => item.fee)
              .filter((f) => safeId(f.guest) === +guestId);
            const guest = fees[0].guest;

            return (
              <Fragment key={guestId}>
                {fees.map((fee, idx) => (
                  <Fragment key={fee.id}>
                    {idx === 0 ? <span>{guest.fullName}</span> : <span />}
                    <span>{feeLabel(fee.type)}</span>
                    <span className="has-text-right">
                      {formatCents(fee.total, false)}
                    </span>
                  </Fragment>
                ))}

                <span />
                <strong className="has-text-green">Refund</strong>
                <strong className="has-text-green has-text-right">
                  {formatCents(guestTotal, false)}
                </strong>
              </Fragment>
            );
          }
        )}
        <DottedHeading
          className="is-grid-column-1-neg-1 margin-top-1 margin-bottom-0 has-text-green"
          heading="h2"
          left={`Total Refund ${refund.payment.cardBrand} (...${refund.payment.cardLastFour})`}
          right={formatCents(refund.total)}
        />
      </div>

      <div className="is-flex margin-top-3" style={maxWidth}>
        <div className="margin-right-4">
          <label htmlFor="refund-reason">Reason for refund</label>
          <Textarea id="refund-reason" value={refund.reason} readOnly />
        </div>
        <div>
          <label htmlFor="refund-requested-by">Requested By:</label>
          <Input id="refund-requested-by" value={refund.requestedBy} readOnly />
        </div>
      </div>

      {!refund.submittedAt && (
        <div className="margin-top-4" style={maxWidth}>
          <div className="is-flex is-justify-content-flex-end">
            <button
              className="button padding-y-1 padding-x-2 is-light-blue is-outlined is-size-7 margin-right-2"
              onClick={onClose}
              disabled={isLoading}
            >
              Close – Do Not Approve
            </button>
            <button
              className="button padding-y-1 padding-x-2 is-light-blue is-size-7"
              onClick={handleApprove}
              disabled={isUnsettled || isLoading}
            >
              Approve
            </button>
          </div>
          <ErrorMessage
            errors={combinedErrors}
            className="margin-top-2 margin-bottom-0"
          />
        </div>
      )}
    </div>
  );
};
