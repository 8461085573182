import { AppContext } from "../Store";
import { DANNO_URL, getActivityDetails, getActivityTotals } from "../api";
import {
  FlatpickrInput,
  fixBlankInputOnSingleDate,
  onDateRangeChange,
} from "../utils";
import {
  ErrorMessage,
  ExternalLink,
  PaymentIndicator,
  SVG,
  Select,
  Tooltip,
  useErrors,
} from "react-components";
import {
  ActivitySortField,
  ActivitySortOrder,
  AdminGetActivityDetailsParams,
  AdminGetActivityDetailsParamsSerialized,
  AdminGetActivityDetailsRes,
  AdminGetActivityTotalsParams,
  AdminGetActivityTotalsRes,
  BookingDO,
  CENTRAL_TZ,
  FeeDO,
  GuestDO,
  TourDO,
  TourYearDO,
  allFinalPaymentFees,
  capitalize,
  countriesByCode,
  depositFee,
  feePayment,
  formatCents,
  fullName,
  hasTourEnded,
  isBookingAnnulled,
  isBookingCancelled,
  isDepositOverdue,
  isFeeWaived,
  isFinalPaymentOverdue,
  isHoldDeleted,
  isHoldExpired,
  isTakenByUnknown,
  nowInCT,
  regionsByCountryCode,
  tppFee,
  welcomeReception,
  yearFromDate,
} from "data-model";
import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import flatpickr from "flatpickr";
import clsx from "clsx";
import { DateTime } from "luxon";

type Country = AdminGetActivityDetailsParams["country"] | "";
type Status = AdminGetActivityDetailsParams["status"] | "";
type Sort = AdminGetActivityDetailsParamsSerialized["sort"];
type Order = AdminGetActivityDetailsParamsSerialized["order"];

const idPrefix = "booking-details";
const limit = 30;

const Activity = () => {
  const [{ db }] = useContext(AppContext);
  if (!db) throw new Error("unreachable");

  const createdAtRef = useRef<FlatpickrInput>(null);
  const departedAtRef = useRef<FlatpickrInput>(null);

  const defaults = dateDefaults();
  const [createdAtFrom, setCreatedAtFrom] = useState(defaults.createdAtFrom);
  const [createdAtTo, setCreatedAtTo] = useState(defaults.createdAtTo);
  const [departedAtFrom, setDepartedAtFrom] = useState(defaults.departedAtFrom);
  const [departedAtTo, setDepartedAtTo] = useState(defaults.departedAtTo);
  const [tour, setTour] = useState("");
  const [country, setCountry] = useState<Country>("");
  const [status, setStatus] = useState<Status>("active-and-travelled");
  const [sort, setSort] = useState<Sort>(["date", "time"]);
  const [order, setOrder] = useState<Order>([1, 1]);
  const [offset, setOffset] = useState(0);
  const [isHoldingCtrl, setIsHoldingCtrl] = useState(false);

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(true);

  const [totals, setTotals] = useState<AdminGetActivityTotalsRes>();
  const [details, setDetails] = useState<AdminGetActivityDetailsRes>({
    total: 0,
    cancelFeeTotal: 0,
    percentOnline: {
      bookings: 0,
      deposits: 0,
      finals: 0,
      tpps: 0,
    },
    bookings: [],
  });

  const currentYear = nowInCT().year;
  const tours = db.tour.docs() as TourDO[];

  const dateIdx = sort.indexOf("date");
  const timeIdx = sort.indexOf("time");
  const dbdIdx = sort.indexOf("dbd");
  const tourIdx = sort.indexOf("tour");
  const pwdIdx = sort.indexOf("pwd");
  const takenByIdx = sort.indexOf("taken-by");

  const fetchActivityTotals = () => {
    const params: AdminGetActivityTotalsParams = {
      createdAtFrom,
      createdAtTo,
      departedAtFrom,
      departedAtTo,
    };
    if (tour) params.tour = tour;
    if (country) params.country = country;
    return getActivityTotals(params);
  };

  const fetchActivityDetails = (offset: number, sort: Sort, order: Order) => {
    const params: AdminGetActivityDetailsParamsSerialized = {
      createdAtFrom,
      createdAtTo,
      departedAtFrom,
      departedAtTo,
      offset,
      sort,
      order,
    };
    if (tour) params.tour = tour;
    if (country) params.country = country;
    if (status) params.status = status;
    return getActivityDetails(params);
  };

  const handleNewSearch = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        const [totals, details] = await Promise.all([
          fetchActivityTotals(),
          fetchActivityDetails(0, sort, order),
        ]);

        setTotals(totals);
        setDetails(details);
        setOffset(0);
      },
      () => setIsLoading(false)
    );
  };

  const handleNavigation = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const isNextBtn = e.currentTarget.dataset.direction === "next";
    setIsLoading(true);
    catchErrors(
      async () => {
        const newOffset = isNextBtn ? offset + limit : offset - limit;

        const details = await fetchActivityDetails(newOffset, sort, order);
        setDetails(details);
        setOffset(newOffset);
      },
      () => setIsLoading(false)
    );
  };

  const handleSort = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoading(true);
    catchErrors(
      async () => {
        const field = e.currentTarget.dataset.sort as ActivitySortField;
        const idx = sort.indexOf(field);

        let newSort: Sort;
        let newOrder: Order;

        if (isHoldingCtrl) {
          newSort = sort.slice();
          newOrder = order.slice();

          if (idx !== -1) {
            // The field is already engaged.
            // `order.length > 1` is for when you sort by only one field,
            // and click it while inadvertently holding Ctrl.
            if (order[idx] === -1 && order.length > 1) {
              // Unselect the field.
              newSort.splice(idx, 1);
              newOrder.splice(idx, 1);
            } else {
              newOrder[idx] = inverseOrder(newOrder[idx]);
            }
          } else {
            newSort.push(field);
            newOrder.push(1);
          }
        } else {
          newSort = [field];
          newOrder = [idx !== -1 ? inverseOrder(order[idx]) : 1];
        }

        const newOffset = 0;
        const details = await fetchActivityDetails(
          newOffset,
          newSort,
          newOrder
        );
        setDetails(details);
        setSort(newSort);
        setOrder(newOrder);
        setOffset(newOffset);
      },
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    handleNewSearch();

    flatpickr(createdAtRef.current!, {
      mode: "range",
      defaultDate: [createdAtFrom, createdAtTo],
      onClose: fixBlankInputOnSingleDate,
      onChange: onDateRangeChange(setCreatedAtFrom, setCreatedAtTo),
    });

    flatpickr(departedAtRef.current!, {
      mode: "range",
      defaultDate: [departedAtFrom, departedAtTo],
      onClose: fixBlankInputOnSingleDate,
      onChange: onDateRangeChange(setDepartedAtFrom, setDepartedAtTo),
    });

    const onKeyDown = (e: KeyboardEvent) => {
      if (isCtrlKey(e.key)) setIsHoldingCtrl(true);
    };
    const onKeyUp = (e: KeyboardEvent) => {
      if (isCtrlKey(e.key)) setIsHoldingCtrl(false);
    };

    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  return (
    <main className="padding-y-2 margin-x-auto">
      <div className="is-flex is-align-items-flex-end margin-bottom-3">
        <button
          className="button is-yellow margin-right-2"
          onClick={handleNewSearch}
          disabled={isLoading}
          style={{ minWidth: 70 }}
        >
          {isLoading ? (
            <SVG path="site/icon/spinner" alt="Spinner" height={18} />
          ) : (
            "Search"
          )}
        </button>

        <div className="is-grid is-grid-template-columns-auto-1fr is-column-gap-2 is-row-gap-0-pt-5 is-align-items-center">
          <span />
          <div className="is-flex">
            <button
              className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-3"
              onClick={() => {
                const today = nowInCT().toISODate();
                createdAtRef.current!._flatpickr.setDate([today, today], true);
              }}
            >
              Today
            </button>
            <button
              className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-3"
              onClick={() => {
                const yesterday = nowInCT().minus({ days: 1 }).toISODate();
                createdAtRef.current!._flatpickr.setDate(
                  [yesterday, yesterday],
                  true
                );
              }}
            >
              Yesterday
            </button>
            <button
              className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-3"
              onClick={() => {
                const last7 = nowInCT().minus({ days: 6 }).toISODate();
                const today = nowInCT().toISODate();
                createdAtRef.current!._flatpickr.setDate([last7, today], true);
              }}
            >
              7 Days
            </button>
            <button
              className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-3"
              onClick={() => {
                const last30 = nowInCT().minus({ days: 29 }).toISODate();
                const today = nowInCT().toISODate();
                createdAtRef.current!._flatpickr.setDate([last30, today], true);
              }}
            >
              30 Days
            </button>
            <button
              className="button is-ghost is-link is-paddingless has-text-weight-normal"
              onClick={() => {
                const last12mo = nowInCT().minus({ months: 12 }).toISODate();
                const today = nowInCT().toISODate();
                createdAtRef.current!._flatpickr.setDate(
                  [last12mo, today],
                  true
                );
              }}
            >
              12 Mo.
            </button>
          </div>
          <label htmlFor={`${idPrefix}-createdAt`}>Activity:</label>
          <input
            ref={createdAtRef}
            id={`${idPrefix}-createdAt`}
            type="date"
            className="input is-width-auto"
          />
        </div>

        <div className="is-grid is-grid-template-columns-auto-1fr is-column-gap-2 is-row-gap-0-pt-5 is-align-items-center margin-left-2">
          <span />
          <div className="is-flex">
            {[currentYear - 1, currentYear, currentYear + 1].map((year) => (
              <button
                key={year}
                className="button is-ghost is-link is-paddingless has-text-weight-normal margin-right-4"
                onClick={() => {
                  departedAtRef.current!._flatpickr.setDate(
                    [`${year}-01-01`, `${year}-12-31`],
                    true
                  );
                }}
              >
                {year}
              </button>
            ))}
          </div>
          <label htmlFor={`${idPrefix}-departedAt`}>Departure:</label>
          <input
            ref={departedAtRef}
            id={`${idPrefix}-departedAt`}
            type="date"
            className="input is-width-auto"
          />
        </div>

        <div className="is-flex is-align-items-center">
          <label htmlFor={`${idPrefix}-tour`} className="margin-x-2">
            Tour:
          </label>
          <Select
            id={`${idPrefix}-tour`}
            value={tour}
            onChange={(e) => setTour(e.currentTarget.value)}
          >
            <option value="">All</option>
            {tours.map((tour) => (
              <option key={tour._id} value={tour._id}>
                {tour._id}
              </option>
            ))}
          </Select>

          <label htmlFor={`${idPrefix}-country`} className="margin-x-2">
            Country:
          </label>
          <Select
            id={`${idPrefix}-country`}
            value={country}
            onChange={(e) => setCountry(e.currentTarget.value as Country)}
          >
            <option value="">All</option>
            <option value="US">US</option>
            <option value="CA">Canada</option>
            <option value="OTHER">Other</option>
          </Select>
        </div>
      </div>

      <ErrorMessage errors={errors} />

      {totals && (
        <div
          className="is-inline-grid is-justify-items-flex-end is-column-gap-5 is-row-gap-0-pt-5"
          style={{
            gridTemplateColumns: `repeat(${2 + totals.series.length}, auto)`,
          }}
        >
          <span />
          <strong>Total</strong>
          {totals.series.map((series) => (
            <strong key={series}>{series}</strong>
          ))}

          {(
            [
              "holds",
              "confirmed",
              "tppPurchased",
              "deleted",
              "expired",
              "cancelled",
            ] as const
          ).map((category) => {
            const isPercentage =
              category === "confirmed" || category === "tppPurchased";
            return (
              <Fragment key={category}>
                <span
                  className={clsx(
                    "is-justify-self-flex-start",
                    isPercentage && "margin-left-3"
                  )}
                >
                  {category === "tppPurchased"
                    ? "TP Purchased"
                    : capitalize(category)}
                </span>
                {["total", ...totals.series].map((key) => (
                  <span key={key}>
                    {totals[category][key] ?? 0}
                    {isPercentage ? "%" : ""}
                  </span>
                ))}
              </Fragment>
            );
          })}

          <div className="is-grid-column-1-neg-1 is-fullwidth">
            <hr className="is-marginless is-fullwidth" />
          </div>

          <span className="is-justify-self-flex-start">Net Gain</span>
          {["total", ...totals.series].map((key) => (
            <span key={key}>
              {(totals.holds[key] ?? 0) -
                (totals.deleted[key] ?? 0) -
                (totals.expired[key] ?? 0) -
                (totals.cancelled[key] ?? 0)}
            </span>
          ))}

          <span className="margin-top-1 is-grid-column-1-neg-1" />

          <span className="is-justify-self-flex-start">
            Sold / {totals.year}
          </span>
          {["total", ...totals.series].map((key) => (
            <span key={key}>{totals.sold[key] ?? 0}</span>
          ))}
          <span className="is-justify-self-flex-start">
            Left / {totals.year}
          </span>
          {["total", ...totals.series].map((key) => (
            <span key={key}>{totals.remaining[key] ?? 0}</span>
          ))}
        </div>
      )}

      <div className="is-flex is-align-items-center margin-y-3">
        <label htmlFor={`${idPrefix}-status`} className="margin-right-2">
          Status:
        </label>
        <Select
          id={`${idPrefix}-status`}
          className="is-width-auto"
          value={status}
          onChange={(e) => setStatus(e.currentTarget.value as Status)}
        >
          <option value="">All</option>
          <option value="active-and-travelled">Active/Travelled</option>
          <option value="deleted">Deleted</option>
          <option value="expired">Expired</option>
          <option value="cancelled">Cancelled</option>
        </Select>

        <Tooltip
          title={`Hold "command" to sort by multiple columns`}
          parentClassName="margin-left-auto"
          titleClassName="is-centered-x"
        >
          <SVG path="site/icon/question-circled-blue" alt="Help" height={16} />
        </Tooltip>
      </div>

      <div
        className="is-grid is-row-gap-0-pt-5"
        style={{
          gridTemplateColumns: "repeat(15, auto) min-content",
          // gridAutoRows: "calc(16px * 1.15 + 7.5px)",
        }}
      >
        <strong>
          <a href="#" onClick={handleSort} data-sort="date">
            Hold Date
            <Caret index={dateIdx} order={order[dateIdx]} total={sort.length} />
          </a>
        </strong>
        <strong>
          <a href="#" onClick={handleSort} data-sort="time">
            Time
            <Caret index={timeIdx} order={order[timeIdx]} total={sort.length} />
          </a>
        </strong>
        <strong title="Days before Departure">
          <a href="#" onClick={handleSort} data-sort="dbd">
            DbD
            <Caret index={dbdIdx} order={order[dbdIdx]} total={sort.length} />
          </a>
        </strong>
        <strong>Booking #</strong>
        <strong>Status</strong>
        <strong>
          <a href="#" onClick={handleSort} data-sort="tour">
            Tour
            <Caret index={tourIdx} order={order[tourIdx]} total={sort.length} />
          </a>
        </strong>
        <strong>Account Owner</strong>
        <strong>
          <a href="#" onClick={handleSort} data-sort="pwd">
            Pwd
            <Caret index={pwdIdx} order={order[pwdIdx]} total={sort.length} />
          </a>
        </strong>
        <strong title="State">St</strong>
        <strong>Ctry</strong>
        <strong>
          <a href="#" onClick={handleSort} data-sort="taken-by">
            Taken By
            <Caret
              index={takenByIdx}
              order={order[takenByIdx]}
              total={sort.length}
            />
          </a>
        </strong>
        <strong>Pax</strong>
        <strong>D</strong>
        <strong>TP</strong>
        <strong>FP</strong>
        <strong title="Cancellation Fees" className="has-text-nowrap">
          CF $
        </strong>

        <hr className="is-grid-column-1-neg-1 is-fullwidth margin-y-1" />

        {details.bookings.map((booking) => {
          const createdAt = DateTime.fromISO(booking.createdAt, {
            zone: CENTRAL_TZ,
          });
          // const tour = db.tour.findByIdOrFail(booking.tour) as TourDO;
          const tourYear = db.tourYear.findByIdOrFail({
            tour_id: booking.tour,
            year: yearFromDate(booking.departedAt),
          }) as TourYearDO;

          const pax = booking.guests.length;
          const deposits = countPayments(booking.guests, depositFee);
          const tpps = countPayments(booking.guests, tppFee);
          const finals = countPayments(booking.guests, allFinalPaymentFees);
          const isAnnulled = isBookingAnnulled(booking);

          const { state, country } = booking.owner.address;

          return (
            <Fragment key={booking.id}>
              <span>{createdAt.toFormat("MM/dd/yyyy")}</span>
              <span>{createdAt.toFormat("hh:mm a")}</span>
              <span>
                {Math.trunc(
                  welcomeReception(
                    booking.departedAt,
                    tourYear.timezoneBegin
                  ).diff(
                    DateTime.fromISO(booking.createdAt, {
                      zone: tourYear.timezoneBegin,
                    }),
                    "days"
                  ).days
                )}
              </span>
              <ExternalLink
                target="_blank"
                href={`${DANNO_URL}/manage-booking?booking-id=${booking.id}&account-id=${booking.owner.id}`}
              >
                {booking.number}
              </ExternalLink>
              <span>{bookingStatus(booking, tourYear.timezoneEnd)}</span>
              {/* <span>{tour.shortName}</span> */}
              <span>{booking.tour}</span>
              <span>{fullName(booking.owner)}</span>
              <span>{booking.owner.passwordCreated ? "Y" : "N"}</span>
              <span title={regionsByCountryCode[country][state]}>{state}</span>
              <span title={countriesByCode[country]}>{country}</span>
              <span>
                {isTakenByUnknown(booking)
                  ? "Unknown"
                  : booking.loginId ?? "Online"}
              </span>
              <span>{pax}</span>
              <span className="is-flex is-align-items-center">
                <PaymentIndicator
                  isAnnulled={isAnnulled}
                  isOverdue={isDepositOverdue(booking)}
                  numOfPax={pax}
                  numOfPayments={deposits.paid}
                />
                <span className="margin-left-1" />
                <PaymentIndicator
                  isAnnulled={isAnnulled}
                  numOfPax={pax}
                  numOfPayments={deposits.online}
                  size="medium"
                />
              </span>
              <span className="is-flex is-align-items-center">
                <PaymentIndicator
                  isAnnulled={isAnnulled}
                  numOfPax={pax}
                  numOfPayments={tpps.paid}
                />
                <span className="margin-left-1" />
                <PaymentIndicator
                  isAnnulled={isAnnulled}
                  numOfPax={pax}
                  numOfPayments={tpps.online}
                  size="medium"
                />
              </span>
              <span className="is-flex is-align-items-center">
                <PaymentIndicator
                  isAnnulled={isAnnulled}
                  isOverdue={isFinalPaymentOverdue(booking)}
                  numOfPax={pax}
                  numOfPayments={finals.paid}
                />
                <span className="margin-left-1" />
                <PaymentIndicator
                  isAnnulled={isAnnulled}
                  numOfPax={pax}
                  numOfPayments={finals.online}
                  size="medium"
                />
              </span>
              <span className="has-text-right">
                {!!booking.cancellations.length &&
                  formatCents(
                    booking.cancellations.reduce((acc, c) => {
                      for (const fee of c.fees) {
                        acc += fee.amount;
                      }
                      return acc;
                    }, 0),
                    false
                  )}
              </span>
            </Fragment>
          );
        })}

        <hr className="is-grid-column-1-neg-1 is-fullwidth margin-y-1" />

        <span>
          {details.bookings.length ? 1 + offset : 0} to{" "}
          {offset + details.bookings.length} of {details.total}
        </span>
        <div
          className="is-flex is-justify-content-center"
          style={{ gridColumn: "2 / 8" }}
        >
          <button
            className="button is-ghost is-link is-paddingless margin-right-3"
            disabled={isLoading || offset === 0}
            onClick={handleNavigation}
          >
            Previous
          </button>
          <button
            className="button is-ghost is-link is-paddingless"
            disabled={
              isLoading || // prevent multiple clicks
              !details.bookings.length || // no search was made yet
              details.bookings.length < limit || // last page
              offset + details.bookings.length === details.total // also last page based on total #
            }
            onClick={handleNavigation}
            data-direction="next"
          >
            Next
          </button>
        </div>
        <span style={{ gridColumn: "10 / 11" }}>Online:</span>
        <span>{details.percentOnline.bookings}%</span>
        <span />
        <span>{details.percentOnline.deposits}%</span>
        <span>{details.percentOnline.tpps}%</span>
        <span>{details.percentOnline.finals}%</span>
        <span className="has-text-right">
          {formatCents(details.cancelFeeTotal, false)}
        </span>
      </div>
    </main>
  );
};

export { Activity };

const dateDefaults = () => {
  const now = nowInCT();
  const createdAtFrom = now.toISODate();
  return {
    createdAtFrom,
    createdAtTo: createdAtFrom,
    departedAtFrom: now.startOf("year").toISODate(),
    departedAtTo: now.endOf("year").toISODate(),
  };
};

const countPayments = (
  guests: GuestDO[],
  feeFn: (guestDO: GuestDO) => FeeDO | FeeDO[] // FP may include adjustments, hence an array
) => {
  return guests.reduce(
    (acc, guest) => {
      let fees = feeFn(guest);
      if (!Array.isArray(fees)) fees = [fees];

      const payments = fees.map((f) => feePayment(f));
      if (fees.every((f, i) => isFeeWaived(f) || payments[i])) {
        acc.paid++;
      }
      if (
        fees.every((_, i) => {
          const p = payments[i];
          return p && !isTakenByUnknown(p) && !p.loginId;
        })
      ) {
        acc.online++;
      }
      return acc;
    },
    { paid: 0, online: 0 }
  );
};

// Only use this for presentation, not for logic.
const bookingStatus = (booking: BookingDO, timezoneEnd: string) => {
  if (isHoldDeleted(booking)) return "Deleted";
  if (isBookingCancelled(booking)) return "Cancelled";
  if (isHoldExpired(booking)) return "Expired";
  if (hasTourEnded(booking.departure.concludedAt, timezoneEnd)) {
    return "Travelled";
  }
  return "Active";
};

function isCtrlKey(key: string) {
  if (navigator.platform.startsWith("Mac")) return key === "Meta"; // `command`
  return key === "Control"; // `Ctrl` (also a match for `control` on Mac)
}

interface CaretProps {
  index: number;
  order: number;
  total: number;
}

const Caret: FC<CaretProps> = ({ index, order, total }) => {
  if (index === -1) return null;
  return (
    <>
      {" "}
      <SVG
        className="is-inline"
        path={`site/icon/caret-${order === 1 ? "up" : "down"}-heavy`}
        alt="Caret"
        height={7}
      />
      {total > 1 && <sup>{index + 1}</sup>}
    </>
  );
};

function inverseOrder(order: ActivitySortOrder) {
  return order === 1 ? -1 : 1;
}
